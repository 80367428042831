import React from 'react';
import { Helmet } from 'react-helmet';
import useSiteMetadata from 'hooks/useSiteMetadata';
import defaultOpenGraphImage from '../images/og-default.jpg';

const SEO = ({
  description = '',
  meta = [],
  image = null,
  title,
  slug = '',
  datePublished = '2020-12-24 11:00:00 -06:00',
  article = false,
  modifiedDate,
}) => {
  const site = useSiteMetadata();
  const metaDescription = description || site.siteMetadata.description;
  const ogImageUrl =
    site.siteMetadata.siteUrl + (image || defaultOpenGraphImage);
  const metaSlug = site.siteMetadata.siteUrl + slug;

  // schema.org in JSONLD format
  // https://developers.google.com/search/docs/guides/intro-structured-data
  // You can fill out the 'author', 'creator' with more data or another type (e.g. 'Organization')

  const schemaOrgWebPage = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    url: site.siteMetadata.siteUrl,
    headline: site.siteMetadata.description,
    inLanguage: 'English',
    mainEntityOfPage: site.siteMetadata.siteUrl,
    description: site.siteMetadata.description,
    name: site.siteMetadata.title,
    author: {
      '@type': 'Person',
      name: site.siteMetadata.author,
    },
    copyrightHolder: {
      '@type': 'Person',
      name: site.siteMetadata.author,
    },
    copyrightYear: new Date(datePublished).getFullYear(),
    creator: {
      '@type': 'Person',
      name: site.siteMetadata.author,
    },
    publisher: {
      '@type': 'Person',
      name: site.siteMetadata.author,
    },
    datePublished: new Date(datePublished),
    dateModified: site.buildTime,
    image: {
      '@type': 'ImageObject',
      url: ogImageUrl,
    },
  };

  // Initial breadcrumb list

  const itemListElement = [
    {
      '@type': 'ListItem',
      item: {
        '@id': site.siteMetadata.siteUrl,
        name: 'Homepage',
      },
      position: 1,
    },
  ];

  let schemaArticle = null;

  if (article) {
    schemaArticle = {
      '@context': 'http://schema.org',
      '@type': 'Article',
      author: {
        '@type': 'Person',
        name: site.siteMetadata.author,
      },
      copyrightHolder: {
        '@type': 'Person',
        name: site.siteMetadata.author,
      },
      copyrightYear: new Date(datePublished).getFullYear(),
      creator: {
        '@type': 'Person',
        name: site.siteMetadata.author,
      },
      publisher: {
        '@type': 'Organization',
        name: site.siteMetadata.author,
        logo: {
          '@type': 'ImageObject',
          url: ogImageUrl,
        },
      },
      datePublished: new Date(datePublished),
      dateModified: new Date(modifiedDate),
      description: metaDescription,
      headline: title,
      inLanguage: 'English',
      url: metaSlug,
      name: title,
      image: {
        '@type': 'ImageObject',
        url: ogImageUrl,
      },
      mainEntityOfPage: metaSlug,
    };
    // Push current blogpost into breadcrumb list
    itemListElement.push({
      '@type': 'ListItem',
      item: {
        '@id': metaSlug,
        name: title,
      },
      position: 2,
    });
  }

  const breadcrumb = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    description: 'Breadcrumbs list',
    name: 'Breadcrumbs',
    itemListElement,
  };

  return (
    <Helmet
      htmlAttributes={{
        lang: site.siteMetadata.lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:url',
          content: metaSlug,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.twitterUsername,
        },
        {
          name: 'twitter:site',
          content: site.siteMetadata.twitterUsername,
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          property: 'image',
          content: ogImageUrl,
        },
        {
          property: 'og:image',
          content: ogImageUrl,
        },
        {
          name: 'twitter:image',
          content: ogImageUrl,
        },
        {
          name: 'twitter:image:alt',
          content: metaDescription,
        },
      ].concat(meta)}
    >
      {/* Insert schema.org data conditionally (webpage/article) + every time (breadcrumbs) */}
      {!article && (
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgWebPage)}
        </script>
      )}
      {article && (
        <script type="application/ld+json">
          {JSON.stringify(schemaArticle)}
        </script>
      )}
      <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
    </Helmet>
  );
};

export default SEO;
