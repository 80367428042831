import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import NavBar from 'components/navBar/navBar';
import ThemeContext from 'store/themeContext';
import useTheme from 'hooks/useTheme';
import useSiteMetadata from 'hooks/useSiteMetadata';
import styledTheme from 'styles/theme';
import GlobalStyle from 'styles/globalStyle';

const Layout = ({ children }) => {
  const [theme, themeToggler] = useTheme();
  const site = useSiteMetadata();
  const { title, author } = site.siteMetadata;
  const copyrightStr = `Copyright © ${new Date().getFullYear()} - ${author}.`;
  const cdaleLove = `Built with ♡ from `;
  const cdaleLoveName = 'Carbondale, IL';
  const cdaleLoveLink = 'https://en.wikipedia.org/wiki/Carbondale,_Illinois';

  return (
    <ThemeProvider theme={styledTheme}>
      <ThemeContext.Provider value={theme}>
        <GlobalStyle />
        <Container>
          <NavBar title={title} themeToggler={themeToggler} />
          {children}
        </Container>
        <Footer role="contentinfo">
          <Copyright aria-label="Copyright">
            {copyrightStr} <br />
            {cdaleLove}
            <RepoLink href={cdaleLoveLink} target="__blank">
              {cdaleLoveName}
            </RepoLink>
          </Copyright>
        </Footer>
      </ThemeContext.Provider>
    </ThemeProvider>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: calc(100vh - var(--footer-height));
  background-color: var(--color-post-background);
`;

const Footer = styled.footer`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 1.6rem 0;
  background-color: var(--color-gray-0);
`;

const Copyright = styled.span`
  font-size: var(--text-sm);
  font-weight: var(--font-weight-regular);
  color: var(--color-gray-6);
  width: 220px;
`;

const RepoLink = styled.a`
  color: var(--color-text);
  &:hover {
    text-decoration: underline;
  }
`;

export default Layout;
